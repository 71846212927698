import React, { Component } from "react"
import { Link } from "gatsby"
import { RedLink } from "../../Link"
import PlusIcon from "../../Icons/PlusIcon"
import {
  DropWrapp,
  DropButton,
  DropButtonText,
  DropButtonImg,
  FadeContent,
} from "./style"

class OtherBuildingsMobileNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
    this.handleFade = this.handleFade.bind(this)
  }
  handleFade() {
    const nextState = !this.state.isVisible
    this.setState({
      isVisible: nextState,
    })
  }
  render() {
    const { isVisible } = this.props
    return (
      <DropWrapp isVisible={isVisible}>
        <DropButton onClick={this.props.open} isVisible={isVisible}>
          <DropButtonText>Steel Buildings</DropButtonText>
          <DropButtonImg isVisible={isVisible}>
            <PlusIcon fill="#1E4156" />
          </DropButtonImg>
        </DropButton>
        <FadeContent isVisible={isVisible} onClick={this.props.onChange}>
          <Link to="/utility-buildings/">Utility Buildings</Link>
          <Link to="/storage-buildings/">Storage Buildings</Link>
          <Link to="/commercial-metal-buildings/">Commercial Buildings</Link>
          <Link to="/metal-building-homes/">Metal Building Homes</Link>
          <Link to="/metal-building-prices/">Metal Building Prices </Link>
          <Link to="/steel-buildings/metal-buildings-kits/">Metal Building Kits </Link>
          <Link to="/metal-sheds/">Metal Sheds</Link>
          <Link to="/metal-warehouses/">Metal Warehouses</Link>
          {/* <Link to="/products/">All Building Products</Link> */}
          {/* <RedLink to="/steel-buildings/">Steel Buildings</RedLink> */}
        </FadeContent>
      </DropWrapp>
    )
  }
}


export default OtherBuildingsMobileNav